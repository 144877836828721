import { SplitText } from 'gsap/SplitText';

export function initTitleReveal({ el, selector }) {
  const els = el.querySelectorAll(selector);
  const observers = [];
  for (let i = 0; i < els.length; i++) {
    if (els[i].classList.contains(`reveal-title-processed`)) {
      return;
    }
    els[i].classList.add(`reveal-title`)
    els[i].classList.add(`reveal-title-processed`)
    const splitText = new SplitText(els[i], {type: "lines, lines", linesClass: "reveal-title__line" })
    splitText.lines.forEach(l => l.innerHTML = `<div class="reveal-title__line-inner">${l.innerHTML}</div>`)
    const observer = new IntersectionObserver((e) => {
      if (!e[0].isIntersecting) {
        return;
      }
      const index = i;
      els[i].classList.add('is-revealed');
      observer.unobserve(els[index]);
      observer.disconnect();
    });
    observer.observe(els[i]);
    observers.push(observer);
  }
  return observers;
}

export function destroyObservers(observers) {
  observers.forEach(o => {
    o.disconnect();
  })
}

export function initSubtleReveal({ el, selector }) {
  const els = el.querySelectorAll(selector);
  for (let i = 0; i < els.length; i++) {
    if (els[i].classList.contains(`subtle-reveal-processed`)) {
      return;
    }
    els[i].classList.add(`subtle-reveal-processed`)
    const observer = new IntersectionObserver((e) => {
      if (!e[0].isIntersecting) {
        return;
      }
      const index = i;
      els[i].classList.add('is-revealed');
      observer.unobserve(els[index]);
      observer.disconnect();
    });
    observer.observe(els[i]);
  }
}
