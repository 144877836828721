import Cookies from 'js-cookie';

if (!Cookies.get('newsletter')) {
  setTimeout(() => {
    document.body.classList.add('is-show-newsletter');
  }, 3000)
}

document.addEventListener('click', e => {
  if (e.target.matches('[data-close-newsletter]')) {
    document.body.classList.remove('is-show-newsletter');
    Cookies.set('newsletter', 1)
  }

  if (e.target.matches('[data-open-newsletter]')) {
    document.body.classList.add('is-show-newsletter');
  }
})
