import { AbstractComponent } from '~wl/components/abstract-component';
import { register } from '~wl/components/registration';
import { BeerListApp } from '../modules/BeerList/BeerListApp';

export class BeerList extends AbstractComponent {
  init() {
    this.beerListApp = new BeerListApp(this.el);
  }

  onUnload() {
    this.beerListApp.destroy();
  }
}

register('beer-list', BeerList);
