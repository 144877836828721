import { SyncMixin } from "./SyncMixin";
import { Core } from "./CoreSmoothScroll";

const VueGl = {
  install(Vue, { componentsMap, debugMode, enabled }) {
    Vue.mixin(SyncMixin);
    window.WL.vueGl.core = Vue.prototype.$vueGl = new Core({
      componentsMap,
      debugMode,
      enabled
    }).init();
  }
};

export { VueGl };
