const SyncMixin = {
  data() {
    return {
      vueGlComponentInstance: null,
      vueGlEl: null,
    }
  },
  computed: {
    vueGlEnabled() {
      return !!this.$vueGl.enabled
    },
  },
  methods: {
    vueGlRegisterComponent(componentOptions) {
      this.vueGlEl = (componentOptions && componentOptions.el) || this.$el
      this.vueGlComponentInstance = this.$vueGl.registerComponent(
        this.vueGlEl,
        `${this.$options.name}Gl`,
        componentOptions
      )
      // this.vueGlMouseEnterHandler = this.vueGlHandleMouseEnter.bind(this)
      // this.vueGlEl.addEventListener('mouseenter', this.vueGlMouseEnterHandler)

      // this.vueGlMouseLeaveHandler = this.vueGlHandleMouseLeave.bind(this)
      // this.vueGlEl.addEventListener('mouseleave', this.vueGlMouseLeaveHandler)
    },
    // vueGlHandleMouseEnter(e) {
    //   if (!this.vueGlComponentInstance) {
    //     return
    //   }
    //   this.vueGlComponentInstance.handleMouseEnter(e.clientX, e.clientY)
    // },
    // vueGlHandleMouseLeave(e) {
    //   if (!this.vueGlComponentInstance) {
    //     return
    //   }
    //   this.vueGlComponentInstance.handleMouseLeave(e.clientX, e.clientY)
    // },
  },
  beforeDestroy() {
    this.$vueGl.deregisterComponent(this.vueGlComponentInstance)
    this.vueGlComponentInstance = null
  },
}

export { SyncMixin }
