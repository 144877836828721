import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export default function createStore() {
  return new Vuex.Store({
    state: {
      isLoading: false,
      products: null,
      revealDirection: 'right',
      isBeerLibrary: false,
    },

    mutations: {
      setInitial(state, initial) {
        state.products = initial.products
        state.revealDirection = initial.revealDirection
        state.isBeerLibrary = initial.isBeerLibrary
      }
    },
  });
}
