export class AbstractComponent {
  constructor(el) {
    this.el = el;
    if (!this.el) {
      return;
    }

    this.setOptions();
    this.loadOptions();
    this.beforeInit();
    this.init();
    this.afterInit();
  }

  setOptions() {
    this.options = {};
  }

  beforeInit() {
  }

  init() {
  }

  afterInit() {
  }

  loadOptions() {
    const options = this.el.dataset.sectionOptions;
    if (!options) {
      return;
    }

    const json = JSON.parse(options);

    this.options = {
      ...this.options,
      ...json,
    };
  }

  onUnload() {
  }
}
