import { AbstractComponent } from '~wl/components/abstract-component';
import { register } from '~wl/components/registration';
import { FeaturedBeerApp } from '../modules/FeaturedBeer/FeaturedBeerApp'

export class FeaturedBeer extends AbstractComponent {
  init() {
    this.featuredBeerApp = new FeaturedBeerApp(this.el);
  }

  onUnload() {
    this.featuredBeerApp.destroy();
  }
}

register('featured-beer', FeaturedBeer);
