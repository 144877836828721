import { MathUtils, Mesh, EquirectangularReflectionMapping } from "three";
import { gsap } from "gsap";
import { VueGlComponent } from "../../libs/vue-gl/VueGlComponent";

export class SingleBeerGl extends VueGlComponent {
  init(
    domElement,
    camera,
    rootScene,
    scrollValue,
    viewportSize,
    componentOptions
  ) {
    super.init(
      domElement,
      camera,
      rootScene,
      scrollValue,
      viewportSize,
      componentOptions
    );

    this.currentXRotationDeg = this.defaultXRotationDeg = this.targetXRotationDeg = 0;
    this.currentYRotationDeg = this.targetYRotationDeg = 900;
    this.currentZRotationDeg = this.defaultZRotationDeg = 0;
    this.defaultYRotationDeg = 0;

    this.targetPanYRotation = 0;
    this.lastPanDeltaX = 0;

    this.isRevealed = false;
    this.isMouseEntered = !window.isTouchEnabledDevice;

    this.currentX = 2;
    this.defaultX = 0;
    this.currentY = this.defaultY = -0.3;
    this.currentZ = this.defaultZ = this.targetZ = 0;

    this.modelUrl = `${window.WL.settings.assetsUrl}/3d-models/can-bottle-440-separate.glb`;
    this.envMapUrl = `${window.WL.settings.assetsUrl}/3d-models/environment-map-v2.jpg`;

    this.isLoadedAndRevealed = false;

    this.rootScene = rootScene;
    this.rootScene.add(this);

    this.normalisedLocalMouseCoordinates.x = 0;
    this.normalisedLocalMouseCoordinates.y = 0.5;
  }

  loadAndReveal(textureUrl, revealDirection) {
    if (this.isLoadedAndRevealed) {
      return Promise.reject("Already loaded and revealed");
    }
    this.isLoadedAndRevealed = true;
    this.textureUrl = textureUrl;
    this.currentX = revealDirection === 'toNext' ? 2 : -2;
    this.currentYRotationDeg = this.targetYRotationDeg = revealDirection === 'toNext' ? 900 : -900;
    return Promise.all([
      this.loadModel(this.modelUrl),
      this.loadTexture(this.envMapUrl),
      this.loadTexture(this.textureUrl)
    ]).then(([gltf, envMap, modelMap]) => {
      return this.initModel(gltf, envMap, modelMap);
    });
  }

  initModel(gltf, envMap, modelMap) {
    return new Promise((resolve, reject) => {
      envMap.mapping = EquirectangularReflectionMapping;

      const ratio = this.bounds.width / this.bounds.height;
      this.gltfScene = gltf.scene;

      gltf.scene.children[1].scale.set(0.25, 0.25, 0.25);
      gltf.scene.children[0].scale.set(0.25, 0.25, 0.25);

      const bodyMaterial = gltf.scene.children[0].material;
      bodyMaterial.transparent = false;
      bodyMaterial.color.convertSRGBToLinear();
      bodyMaterial.map = modelMap;

      const topBottomMaterial = bodyMaterial.clone();

      gltf.scene.children[1].material = topBottomMaterial;
      topBottomMaterial.roughness = 0.2;
      topBottomMaterial.metalness = 1;
      topBottomMaterial.envMap = envMap;

      bodyMaterial.roughness = 0.35;
      bodyMaterial.metalness = 0.9;
      bodyMaterial.envMap = envMap;
      // bodyMaterial.refractionRatio = 1;
      bodyMaterial.envMapIntensity = 0.2;

      this.add(this.gltfScene);
      this.rootScene.add(this);

      this.gltfScene.scale.set(1, ratio, 1);

      this.gltfScene.position.set(this.currentX, this.currentY, this.currentZ);
      this.gltfScene.rotation.set(
        MathUtils.degToRad(this.currentXRotationDeg),
        MathUtils.degToRad(this.currentYRotationDeg),
        MathUtils.degToRad(this.currentZRotationDeg)
      );

      setTimeout(() => {
        gsap.to(this, {
          currentYRotationDeg: this.targetYRotationDeg,
          currentXRotationDeg: this.targetXRotationDeg,
          currentX: 0,
          currentZ: this.targetZ,
          duration: 1,
          onComplete: () => {
            this.isRevealed = true;
          }
        });
      }, 200)

      resolve(true);
    });
  }

  handleMouseEnter() {
    this.isMouseEntered = true
    if (this.resetTimeout) {
      clearTimeout(this.resetTimeout);
    }
    this.resetTimeout = null;
  }

  handleTouchPan(deltaX, isFinal) {
    this.targetPanYRotation -= this.lastPanDeltaX - deltaX;
    this.lastPanDeltaX = isFinal ? 0 : deltaX;
  }

  handleMouseLeave() {
    this.resetTimeout = setTimeout(() => this.resetMouseEntered(), 1000)
  }

  resetMouseEntered() {
    this.isMouseEntered = false;
    if (this.resetTimeout) {
      clearTimeout(this.resetTimeout);
    }
    this.resetTimeout = null;
  }

  // handleMouseMove(normLocalX, normLocalY) {
  //   this.normalisedLocalMouseCoordinates.x = normLocalX;
  //   this.normalisedLocalMouseCoordinates.y = normLocalY;
  // }

  // resetMouseMove() {
  //   this.normalisedLocalMouseCoordinates.x = 0;
  //   this.normalisedLocalMouseCoordinates.y = 0.5;
  // }

  handleResize(viewportSize) {
    super.handleResize(viewportSize);
    if (!this.gltfScene) {
      return;
    }
    const ratio = this.bounds.width / this.bounds.height;
    this.gltfScene.scale.set(1, ratio, 1);
  }

  handleRaf(delta) {
    super.handleRaf(delta);
    if (!this.gltfScene) {
      return;
    }

    if (this.isMouseEntered) {
      this.targetYRotationDeg = Math.min(Math.max(0, (this.normalisedViewportMouseCoordinates.x - 0.55) / 0.4), 1) * 360;
      this.targetXRotationDeg = this.normalisedViewportMouseCoordinates.x < 0.5 || this.viewportSize.width < 1024 ? 0 : (-0.5 + this.normalisedViewportMouseCoordinates.y) * 60;
      this.targetZ =  this.normalisedViewportMouseCoordinates.x < 0.5 || this.viewportSize.width < 1024 ? 0 : Math.abs((-0.5 + this.normalisedViewportMouseCoordinates.y) * 0.2) * -1;
    } else {
      this.targetYRotationDeg = this.targetPanYRotation || 0;
      this.targetXRotationDeg = 0
      this.targetZ = 0
    }

    if (this.isRevealed) {
      gsap.to(this, {
        duration: 0.3,
        currentYRotationDeg: this.targetYRotationDeg,
        currentXRotationDeg: this.targetXRotationDeg,
        currentZ: this.targetZ
      });
    }

    this.gltfScene.position.set(this.currentX, this.currentY, this.currentZ);
    this.gltfScene.rotation.set(
      MathUtils.degToRad(this.currentXRotationDeg),
      MathUtils.degToRad(this.currentYRotationDeg),
      MathUtils.degToRad(this.currentZRotationDeg)
    );
  }

  destroy() {
    super.destroy();
    if (this.resetTimeout) {
      clearTimeout(this.resetTimeout);
    }
    this.resetTimeout = null;
  }
}
