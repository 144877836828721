import barba from '@barba/core';

export function setNavActiveEl({ html, nav }) {
  const regex = new RegExp('<li class="nav__item is-current[^>]+>(.*)</li>', 'gi');
  const matches = html.match(regex);

  let currentPath = null;
  if (matches && matches.length) {
    let element = document.createElement('ul');
    element.innerHTML = matches[0];
    currentPath = element.querySelector('a.is-current').getAttribute('href');
    if (currentPath) {
      currentPath = barba.url.parse(currentPath).path;
    }
    element = null;
  }

  nav.querySelectorAll('a.nav__link').forEach((navEl) => {
    const navPath = barba.url.parse(navEl.href).path;
    if (currentPath === navPath) {
      navEl.classList.add('is-current');
      navEl.parentNode.classList.add('is-current');
    } else {
      navEl.classList.remove('is-current');
      navEl.parentNode.classList.remove('is-current');
    }
  });
}
