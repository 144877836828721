import { gsap } from "gsap";
import { MathUtils, Mesh, EquirectangularReflectionMapping } from "three";
import { VueGlComponent } from "../../libs/vue-gl/VueGlComponent";

export class FeaturedBeerListGl extends VueGlComponent {
  init(
    domElement,
    camera,
    rootScene,
    scrollValue,
    viewportSize,
    componentOptions
  ) {
    super.init(
      domElement,
      camera,
      rootScene,
      scrollValue,
      viewportSize,
      componentOptions
    );

    this.modelUrl = `${window.WL.settings.assetsUrl}/3d-models/can-bottle-440-separate.glb`;
    this.envMapUrl = `${window.WL.settings.assetsUrl}/3d-models/environment-map-v2.jpg`;

    this.isLoadedAndRevealed = false;

    this.seasonalProducts = [];
    this.coreProducts = [];

    this.rootScene = rootScene;
    this.rootScene.add(this);

    this.revealProgress = 0;

    this.sceneMobileOffsetX = viewportSize.width < 768 ? -0.20 : 0;
    this.sceneMobileOffsetY = viewportSize.width < 768 ? -0.03 : 0;
    this.sceneMobileScaleFactor = viewportSize.width < 768 ? 1.5 : 1;
  }

  loadAndReveal(products) {
    if (this.isLoadedAndRevealed) {
      return Promise.reject("Already loaded and revealed");
    }
    this.isLoadedAndRevealed = true;

    return Promise.all([
      this.loadModel(this.modelUrl),
      this.loadTexture(this.envMapUrl),
      ...products.map(p => this.loadTexture(p.textureUrl))
    ]).then(results => {
      return this.initModel(results[0], results[1], results.slice(2));
    });
  }

  initModel(gltf, envMap, modelTextures) {
    return new Promise((resolve, reject) => {
      envMap.mapping = EquirectangularReflectionMapping;

      const ratio = this.bounds.width / this.bounds.height;

      const bodyMesh = gltf.scene.children[0];
      const topBottomMesh = gltf.scene.children[1];
      bodyMesh.scale.set(0.1, 0.1, 0.1);
      topBottomMesh.scale.set(0.1, 0.1, 0.1);
      this.gltfScene = gltf.scene;

      const bodyMaterial = gltf.scene.children[0].material;
      bodyMaterial.transparent = false;
      bodyMaterial.color.convertSRGBToLinear();

      const topBottomMaterial = bodyMaterial.clone();
      gltf.scene.children[1].material = topBottomMaterial;
      topBottomMaterial.roughness = 0.2;
      topBottomMaterial.metalness = 1;
      topBottomMaterial.envMap = envMap;

      bodyMaterial.roughness = 0.35;
      bodyMaterial.metalness = 0.9;
      bodyMaterial.envMap = envMap;
      bodyMaterial.envMapIntensity = 0.2;

      bodyMesh.material.map = modelTextures[0];
      topBottomMesh.material.map = modelTextures[0];

      this.productMeshes = [
        {
          currentX: 0,
          currentY: -0.2 + this.sceneMobileOffsetY,
          currentZ: 0,
          currentXRotationDeg: 90,
          currentYRotationDeg: 0,
          currentZRotationDeg: 360,
          bodyMesh: bodyMesh,
          topBottomMesh: topBottomMesh
        }
      ];

      let currentModelTextureIndex = 0;
      let pairIndex = 1;
      let pairCounter = 0;
      modelTextures.slice(1).forEach(() => {
        currentModelTextureIndex++;
        const bodyMeshClone = bodyMesh.clone();
        const topBottomMeshClone = topBottomMesh.clone();
        bodyMeshClone.traverse(node => {
          if (node.isMesh) {
            node.material = node.material.clone();
          }
        });
        bodyMeshClone.material.map = modelTextures[currentModelTextureIndex];
        gltf.scene.add(bodyMeshClone);
        gltf.scene.add(topBottomMeshClone);
        const obj = {
          currentX: 0,
          currentY: -0.2 + this.sceneMobileOffsetY,
          currentZ: -0.15 * (currentModelTextureIndex),
          currentXRotationDeg: 90,
          currentYRotationDeg: 0,
          currentZRotationDeg: 360 * (currentModelTextureIndex % 2 === 1 ? -1 : 1),
          bodyMesh: bodyMeshClone,
          topBottomMesh: topBottomMeshClone
        };
        this.productMeshes.push(obj);
        pairCounter++;
        if (pairCounter === 2) {
          pairIndex++;
          pairCounter = 0;
        }
      });

      this.add(this.gltfScene);
      this.rootScene.add(this);

      this.gltfScene.scale.set(this.sceneMobileScaleFactor, ratio * this.sceneMobileScaleFactor, this.sceneMobileScaleFactor);

      this.gltfScene.rotation.set(
        MathUtils.degToRad(0),
        MathUtils.degToRad(0),
        MathUtils.degToRad(0)
      );

      pairIndex = 1;
      pairCounter = 0;

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: this.domElement,
          start: "top center+=5%",
          end: "+=55%", // end after scrolling 500px beyond the start
          scrub: window.isTouchEnabledDevice ? false : 0.3,
        }
      });

      this.productMeshes.forEach((p, i) => {
        if (i === 0) {
          tl.to(p, {
            currentX: 0.15 + this.sceneMobileOffsetX,
            currentY: -0.2 + this.sceneMobileOffsetY,
            currentYRotationDeg: 0,
            currentZRotationDeg: 0,
            duration: window.isTouchEnabledDevice ? 0.5 : 1.5,
            delay: 0
          }, 0);
        } else {
          tl.to(p, {
            currentX:
              0.15 + 0.10 * (pairIndex) * (pairCounter % 2 === 1 ? -1 : 1) + this.sceneMobileOffsetX,
            currentY: -0.2 + this.sceneMobileOffsetY,
            currentZ: -0.08 * (pairIndex + 1),
            currentYRotationDeg: 0,
            currentZRotationDeg: 0,
            duration: (window.isTouchEnabledDevice ? 0.3 : 1) + i*0.2,
          }, 0);
          pairCounter++;
          if (pairCounter === 2) {
            pairIndex++;
            pairCounter = 0;
          }
        }
      });

      resolve(true);
    });
  }

  handleResize(viewportSize) {
    super.handleResize(viewportSize);
    if (!this.gltfScene) {
      return;
    }
    const ratio = this.bounds.width / this.bounds.height;
    this.gltfScene.scale.set(this.sceneMobileScaleFactor, ratio * this.sceneMobileScaleFactor, this.sceneMobileScaleFactor);
    this.sceneMobileOffsetX = viewportSize.width < 768 ? -0.15 : 0;
  }

  handleRaf(delta) {
    super.handleRaf(delta);
    if (!this.productMeshes) {
      return;
    }

    this.productMeshes.forEach((p, i) => {
      p.bodyMesh.position.set(p.currentX, p.currentY, p.currentZ);
      p.bodyMesh.rotation.set(
        MathUtils.degToRad(p.currentXRotationDeg),
        MathUtils.degToRad(p.currentYRotationDeg),
        MathUtils.degToRad(p.currentZRotationDeg)
      );

      p.topBottomMesh.position.set(p.currentX, p.currentY, p.currentZ);
      p.topBottomMesh.rotation.set(
        MathUtils.degToRad(p.currentXRotationDeg),
        MathUtils.degToRad(p.currentYRotationDeg),
        MathUtils.degToRad(p.currentZRotationDeg)
      );
    });
  }
}
