import Scrollbar from 'smooth-scrollbar';
import { Cache } from "three";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/src/ScrollTrigger";
import { ModalPlugin } from "../smooth-scroll/ModalPlugin";
export class Core {
  constructor({ componentsMap, debugMode = false, enabled = true }) {
    this.enabled = enabled;
    this.components = [];
    this.componentsMap = componentsMap;
    this.debugMode = debugMode;
    this.lastScrollY = 0;
    this.scrollValue = { x: window.scrollX, y: window.scrollY };
    this.normalisedViewportMouseCoordinates = { x: 0, y: 0 };

    Cache.enabled = true;
    return this;
  }

  init() {
    if (!this.enabled) {
      document
        .getElementsByTagName("html")[0]
        .classList.add("is-vuegl-disabled");
      return this;
    }
    document.getElementsByTagName("html")[0].classList.add("is-vuegl-enabled");
    this.viewportSize = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    this.resizeHandler = this.handleResize.bind(this);
    window.addEventListener("resize", this.resizeHandler);

    // this.scrollHandler = this.handleScroll.bind(this);
    // document.addEventListener("scroll", this.scrollHandler);

    this.mousemoveHandler = this.handleMousemove.bind(this)
    window.addEventListener('mousemove', this.mousemoveHandler)

    return this;
  }

  updateViewportSize() {
    if (!this.enabled) {
      return;
    }
    this.viewportSize = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    if (this.camera) {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
    }
    if (this.renderer) {
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.setScissor(0, 0, window.innerWidth, window.innerHeight);
      this.renderer.setViewport(0, 0, window.innerWidth, window.innerHeight);
    }
    this.components.forEach(c => {
      c.handleResize(this.viewportSize, this.scrollValue);
    });
  }

  registerScroll(el) {
    if (!this.enabled) {
      return;
    }
    Scrollbar.use(ModalPlugin);
    this.scrollEl = document.querySelector('.scroll-container');
    window.smoothScrollInstace = this.scrollInstance = Scrollbar.init(
      this.scrollEl, { damping: 0.1, renderByPixels: true, delegateTo: document, alwaysShowTracks: false, continuousScrolling: false }
    );

    this.scrollHandler = this.handleScroll.bind(this);
    this.scrollInstance.addListener(this.scrollHandler);
    this.scrollInstance.addListener(ScrollTrigger.update);

    const scrollInstance = this.scrollInstance
    ScrollTrigger.scrollerProxy(this.scrollEl, {
      scrollTop(value) {
        if (arguments.length) {
          scrollInstance.scrollTop = value;
        }
        return scrollInstance.scrollTop;
      }
    });
    ScrollTrigger.defaults({ scroller: this.scrollEl });

  }

  registerCanvas(
    canvasComponent,
    canvasEl,
    camera,
    renderer,
    rootScene,
    renderCallback
  ) {
    if (!this.enabled) {
      return;
    }
    this.canvasComponent = canvasComponent;
    this.canvasEl = canvasEl;
    this.camera = camera;
    this.renderer = renderer;
    this.rootScene = rootScene;
    this.renderCallback = renderCallback;

    this.rafHandler = this.handleRaf.bind(this);
    gsap.ticker.add(this.rafHandler);

    this.isCanvasRegistered = true;
  }

  forceUpdate() {
    if (!this.enabled) {
      return;
    }
    this.updateViewportSize();
    this.scrollInstance.update();
    ScrollTrigger.refresh();
  }

  registerComponent(domElement, vueGlComponentName, componentOptions) {
    if (!this.enabled) {
      return;
    }
    if (!this.componentsMap[vueGlComponentName]) {
      return;
    }
    const component = new this.componentsMap[vueGlComponentName]();
    component.init(
      domElement,
      this.camera,
      this.rootScene,
      this.scrollValue,
      this.viewportSize,
      componentOptions
    );
    this.components.push(component);
    return component;
  }

  handleScroll() {
    const scrollY = this.scrollInstance.offset.y;

    if (this.lastScrollY > 100) {
      document.body.classList.toggle('is-scrolled-up',scrollY < this.lastScrollY)
      document.body.classList.toggle('is-scrolled-down',scrollY > this.lastScrollY)
    } else {
      document.body.classList.remove('is-scrolled-up')
      document.body.classList.remove('is-scrolled-down')
    }

    this.lastScrollY = scrollY > 0 ? scrollY : 0;
    // this.normalisedViewportMouseCoordinates.x += e.delta.x / this.viewportSize.width
    // this.normalisedViewportMouseCoordinates.y += e.delta.y / this.viewportSize.height

    // console.log(this.scrollInstance.offset.y / this.scrollInstance.limit.y)
    this.scrollValue.x = this.scrollInstance.offset.x;
    this.scrollValue.y = scrollY;

    // this.handleMousemove({
    //   clientX: this.viewportMouseCoordinates.x,
    //   clientY: this.viewportMouseCoordinates.y,
    // })

    this.components.forEach(c => {
      c.handleScroll(this.scrollValue);
    });

    // TODO update store
  }

  // handleScroll() {
  //   this.scrollValue.x = window.scrollX;
  //   this.scrollValue.y = window.scrollY;
  // }

  handleMousemove(e) {
    this.normalisedViewportMouseCoordinates.x =
      e.clientX / this.viewportSize.width;
    this.normalisedViewportMouseCoordinates.y =
      e.clientY / this.viewportSize.height;
  }

  handleResize() {
    this.updateViewportSize();
    setTimeout(() => {
      this.updateViewportSize();
    }, 1000);
  }

  handleRaf(_t, delta) {
    this.components.forEach(c => {
      c.handleScroll(this.scrollValue);
      c.updateMouseCoordinates(this.normalisedViewportMouseCoordinates)
      c.handleRaf(delta);
    });
    if (this.renderCallback) {
      this.renderCallback(delta);
    }
  }

  deregisterComponent(component) {
    if (!this.enabled) {
      return;
    }
    if (component) {
      component.destroy();
      this.components.splice(this.components.indexOf(component), 1);
    }
  }

  deregisterScroll() {
    if (!this.enabled) {
      return;
    }
    this.scrollInstance.destroy();
    this.scrollInstance = null;
  }

  deregisterCanvas() {
    if (!this.enabled) {
      return;
    }

    function cleanMaterial(material) {
      material.dispose();
      for (const key of Object.keys(material)) {
        const value = material[key];
        if (value && typeof value === "object" && "minFilter" in value) {
          value.dispose();
        }
      }
    }

    this.rootScene.traverse(object => {
      if (!object.isMesh) return;
      object.geometry.dispose();
      if (object.material.isMaterial) {
        cleanMaterial(object.material);
      } else {
        for (const material of object.material) cleanMaterial(material);
      }
    });

    this.renderer.dispose();

    if (this.rAfHandler) {
      gsap.ticker.remove(this.rafHandler);
      this.rAfHandler = null;
    }

    // TODO destroy all components, but leave their defs
    this.components.forEach(c => {
      if (c) {
        c.destroy();
      }
    });
    this.components = [];

    this.canvasComponent = null;
    this.canvasEl = null;
    this.camera = null;
    this.renderer = null;
    this.rootScene = null;
    this.renderCallback = null;
    this.isCanvasRegistered = false;

    this.canvasComponent = canvasComponent;
    this.canvasEl = canvasEl;
    this.camera = camera;
    this.renderer = renderer;
    this.rootScene = rootScene;
    this.renderCallback = renderCallback;
  }

  destroy() {
    if (!this.enabled) {
      return;
    }

    this.deregisterCanvas();

    window.removeEventListener("resize", this.resizeHandler);
    window.removeEventListener("mousemove", this.mousemoveHandler);
    // TODO destroy all components and three instances
  }
}
