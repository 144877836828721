import '../styles/style.scss'

import "./polyfills";
import barba from "@barba/core";
import barbaPrefetch from '@barba/prefetch';
import lazySizes from "lazysizes";
import Vue from "vue";
import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText";
import { ScrollTrigger } from "gsap/src/ScrollTrigger";
import { load, loadAll, unloadAll } from "~wl/components/registration";
import { setNavActiveEl } from "./utils/nav";
import { VueGl } from "./libs/vue-gl/VueGl";
import MobileDetect from "mobile-detect";
import { isWebGLAvailable } from "./libs/vue-gl/utils";

import { CanBottle440ListGl } from "./vue-gl/can-bottle-440-list/CanBottle440ListGl";
import { FeaturedBeerListGl } from "./vue-gl/featured-beer-list/FeaturedBeerListGl";
import { SingleBeerGl } from "./vue-gl/single-beer/SingleBeerGl";

import "./components";
import { isIpad } from "./utils/mobile";
import { destroyObservers, initTitleReveal } from "./utils/animations";
import { scrollLock } from "./utils/scroll";

barba.use(barbaPrefetch);
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.init = false;

gsap.registerPlugin(SplitText);
gsap.registerPlugin(ScrollTrigger);
class App {
  constructor() {
    window.WL.state = window.WL.state || {};
    this.app = document.getElementById("main");
    this.nav = document.getElementById("header-menu");
    this.header = document.getElementById("header");
    this.footer = document.getElementById("footer");
    this.loader = document.getElementById("loader");
    this.initLazyload();
    this.initMobileDetect();

    this.domReady().then(() => {
      this.initTransitions();
      this.initVueGl();
      this.resizeOnImagesReady();
      this.setWindowHeight();
      this.initDeviceOrientationChange();
      this.initPopups();
    });
  }

  domReady() {
    return new Promise(resolve => {
      document.addEventListener("DOMContentLoaded", resolve);
    });
  }

  initPopups() {
    load("age-gate", document.querySelector('[data-section-component="age-gate"]'));
    load("cookies-popup", document.querySelector('[data-section-component="cookies-popup"]'));
  }

  initDeviceOrientationChange() {
    window.addEventListener('orientationchange', () => {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 1000);
    });
  }

  initMobileDetect() {
    window.md = new MobileDetect(window.navigator.userAgent);
    window.isTouchEnabledDevice = isIpad() || window.md.tablet() || window.md.mobile();
  }

  initLazyload() {
    this.lazySizes = lazySizes;
    this.lazySizes.cfg.lazyClass = "is-lazy";
    this.lazySizes.cfg.loadedClass = "is-loaded";
    this.lazySizes.cfg.loadingClass = "is-loading";
    this.lazySizes.cfg.preloadClass = "is-preload";
    this.lazySizes.cfg.init = false;
    this.resetLazyload();

    document.addEventListener("lazybeforeunveil", () => {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    });
  }

  setWindowHeight() {
    // document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    document.documentElement.style.setProperty(
      "--scrollBarWidth",
      `${window.innerWidth - document.documentElement.clientWidth}px`
    );
  }

  resetLazyload() {
    this.lazySizes.init();
  }

  initVueGl() {
    Vue.use(VueGl, {
      enabled:
        // !isIpad() &&
        // !window.md.tablet() &&
        // !window.md.mobile() &&
        isWebGLAvailable(),
      // enabled: false,
      debugMode: false,
      componentsMap: {
        CanBottle440ListGl,
        FeaturedBeerListGl,
        SingleBeerGl
      }
    });
    load("gl-canvas", document.getElementById("glCanvas"));
  }

  resizeOnImagesReady() {
    Promise.all(
      Array.from(document.images)
        .filter(img => !img.complete)
        .map(
          img =>
            new Promise(resolve => {
              img.onload = img.onerror = resolve;
            })
        )
    ).then(() => {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    });
  }

  loadSections(container) {
    loadAll(container || this.app);
  }

  unloadSections(container) {
    unloadAll(container || this.app);
  }

  initTransitions() {
    barba.init({
      timeout: 0,
      prevent: ({ el }) => (el.href && (el.href.includes('checkout') || el.href.includes('my-account'))),
      debug: true,
      views: [
        {
          namespace: "home",
          beforeLeave: ({ current, next }) => {
            if (current.url.path.includes('checkout') || current.url.path.includes('my-account')) {
              window.location = next.url.href
            }

            return new Promise(r => {
              this.loader.classList.add("is-visible");
              setTimeout(() => {
                r();
              }, 50);
              setTimeout(() => {
                if (window.WL.state.titleRevealObservers) {
                  destroyObservers(window.WL.state.titleRevealObservers);
                  window.WL.state.titleRevealObservers = [];
                }
                this.unloadSections(current.container);
              }, 500);
            });
          },
          beforeEnter: ({ next }) => {
            if (document.body.classList.contains("is-menu-open")) {
              window.WL.header && window.WL.header.closeMenu();
            }
            document.body.setAttribute(
              "class",
              next.container.dataset.bodyClass
            );
            setNavActiveEl({ html: next.html, nav: this.nav });
            this.loadSections(next.container);

            const newFooter = next.container.querySelector('.footer');
            if (newFooter) {
              document.documentElement.style.setProperty(
                "--footerColor",
                `${newFooter.style.color}`
              );
            }
            window.WL.header.updateFooterIntersection(newFooter);
          }
        }
      ],
      transitions: [
        {
          leave: () => {
            return new Promise(r => {
              scrollLock.disableScroll(this.loader, 'loader');
              this.loader.classList.add("is-leaving");
              setTimeout(() => r(), 500);
            });
          },
          enter: () => {
          },
          after: data => {
            return new Promise(resolve => {
              // const nextLoaderColorContainer = data.next.container.querySelector(
              //   ".loader-color"
              // );
              // const nextColor =
              //   (nextLoaderColorContainer &&
              //     nextLoaderColorContainer.dataset.color) ||
              //   COLOR_RUST;
              this.loader.classList.add("is-ready");
              setTimeout(() => {
                this.loader.classList.remove("is-leaving");
                this.loader.classList.add("is-left");
                window.dispatchEvent(new Event("resize"));
                scrollLock.enableScroll('loader');
                this.resizeOnImagesReady();
                this.resetLazyload();
                window.WL.state.titleRevealObservers = initTitleReveal({ el: data.next.container, selector: '.reveal-title' });
                // initSubtleReveal({ el: data.next.container, className: 'subtle-reveal' });
                if (window.WL.vueGl.core.enabled) {
                  window.WL.vueGl.core.scrollInstance.scrollTo(0, 0);
                  window.WL.vueGl.core.forceUpdate();
                }

                window.scrollTo(0, 0);
                window.smoothScrollInstace && window.smoothScrollInstace.scrollTo(0, 0)

                resolve();
                setTimeout(() => {
                  this.loader.classList.remove(
                    "is-left",
                    "is-ready",
                    "is-visible"
                  );
                }, 500);
              }, 500);
            });
          },
          once: data => {
            if (
              data.next.container.dataset.bodyClass.includes('page-checkout') ||
              data.next.container.dataset.bodyClass.includes('page-my-account')
            ) {
              if (window.WL.vueGl.core.enabled) {
                window.WL.vueGl.core.deregisterScroll()
                smoothScrollInstace.destroy()
              }
            }

            this.loader.classList.add("is-leaving", "is-ready");
            this.loader.classList.remove("is-initial");
            scrollLock.disableScroll(this.loader, 'loader');

            setTimeout(() => {
              this.loader.classList.remove("is-leaving");
              this.loader.classList.add("is-left");
              window.dispatchEvent(new Event("resize"));
              window.WL.state.titleRevealObservers = initTitleReveal({ el: data.next.container, selector: '.reveal-title' });
              // initSubtleReveal({ el: data.next.container, className: 'subtle-reveal' });
              if (window.WL.vueGl.core.enabled) {
                window.WL.vueGl.core.scrollInstance.scrollTo(0, 0);
                window.WL.vueGl.core.forceUpdate();
              }
              setTimeout(() => {
                this.loader.classList.remove(
                  "is-left",
                  "is-ready",
                  "is-visible"
                );
                scrollLock.enableScroll('loader');
              }, 500);
            }, 500);
          }
        }
      ]
    });
  }
}

window.WL = window.WL || {};
window.WL.app = new App();
