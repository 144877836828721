import {debounce} from "lodash/function.js";

const triggerChange = debounce((target) => {
  target.dispatchEvent(new Event('change', {bubbles: true}))
}, 500)

const checkForClickActions = (e) => {
  const target = e.target

  if (target.matches('[data-quantity-minus]')) {
    const value = Number(target.nextElementSibling.value)
    target.nextElementSibling.value = value > 1 ? value - 1 : target.nextElementSibling.min

    triggerChange(target.nextElementSibling)
  }

  if (target.matches('[data-quantity-plus]')) {
    const value = Number(target.previousElementSibling.value)
    const newValue = value + 1
    const maxValue = Number(target.previousElementSibling.max)
    target.previousElementSibling.value =  maxValue && newValue > maxValue ? value : newValue

    triggerChange(target.previousElementSibling)
  }
}

document.addEventListener('click', checkForClickActions)
