import { AbstractComponent } from "~wl/components/abstract-component";
import { register } from "~wl/components/registration";

export class Marquee extends AbstractComponent {
  init() {
    if (this.el.classList.contains('is-always-animated')) {
      // weird bug on mobile iOS, sometimes animation does not start
      setTimeout(() => {
        this.el.classList.add('is-animated');
      })
      return;
    }
    this.observer = new IntersectionObserver((e) => this.handleIntersectionObserver(e));
    this.isVisible = false;
    this.observer.observe(this.el);
  }

  handleIntersectionObserver(entries) {
    this.isVisible = entries[0].isIntersecting;
    if(this.isVisible) {
      this.el.classList.add('is-animated')
    } else {
      this.el.classList.remove('is-animated')
    }
  }

  onUnload() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  }
}

register("marquee", Marquee);
