import { AbstractComponent } from "~wl/components/abstract-component";
import { register } from "~wl/components/registration";

export class Map extends AbstractComponent {
  init() {
    const observer = new IntersectionObserver((e) => {
      if (e[0].isIntersecting) {
        observer.unobserve(this.el);
        observer.disconnect();
        this.lazyLoadScript()
      }
    });
    observer.observe(this.el)
  }

  lazyLoadScript () {
    if (window.google) {
      this.initMap();
      return;
    }
    const script = document.createElement('script')
    script.async = true
    script.src = window.WL.settings.googleMapsUrl
    document.body.append(script)
    script.onload = this.initMap
  }

  initMap() {
    const mapEl = document.getElementById("map");

    if (!mapEl || google === undefined) return;

    const mapStyle = JSON.parse(mapEl.dataset.style);

    const mapOptions = {
      zoom: 12,
      scrollwheel: false,
      disableDefaultUI: true,
      zoomControl: true,
      styles: mapStyle
    };

    const map = new google.maps.Map(mapEl, mapOptions);
    const mobileCenter = window.innerWidth < 1100 ? "marker" : "center";

    map.setCenter({
      lat: Number(mapEl.dataset[mobileCenter].split(",")[0]),
      lng: Number(mapEl.dataset[mobileCenter].split(",")[1])
    });

    new google.maps.Marker({
      map: map,
      position: {
        lat: Number(mapEl.dataset.marker.split(",")[0]),
        lng: Number(mapEl.dataset.marker.split(",")[1])
      },
      icon: {
        url: mapEl.dataset.icon,
        scaledSize: new google.maps.Size(68, 70)
      }
    });
  }

  onUnload() {}
}

register("map", Map);
