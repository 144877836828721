<template>
  <div class="single-beer-product">
    <div v-if="vueGlEnabled" ref="canModel" class="single-beer-product__model"></div>
    <transition name="fade">
      <div v-if="!vueGlEnabled && isRevealed" v-html="product.listingImage" class="single-beer-product__fallback-img" />
    </transition>
  </div>
</template>

<script>
export default {
  name: "SingleBeer",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isRevealed: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.vueGlRegisterComponent({ el: this.$refs.canModel });
    })
  },
  methods: {
    loadAndReveal() {
      this.isRevealed = true;
      if (!this.product.textureUrl || !this.vueGlEnabled) {
        return Promise.resolve(true);
      }
      return this.vueGlComponentInstance.loadAndReveal(this.product.textureUrl, window.WL.state && window.WL.state.productNavigationDirection && window.WL.state.productNavigationDirection || 'toNext');
    },
    handleMouseEnter() {
      if(!this.vueGlEnabled) {
        return;
      }
      this.vueGlComponentInstance.handleMouseEnter();
    },
    handleMouseLeave() {
      if(!this.vueGlEnabled) {
        return;
      }
      this.vueGlComponentInstance.handleMouseLeave();
    },
    handleTouchPan(deltaX, isFinal) {
      if(!this.vueGlEnabled) {
        return;
      }
      this.vueGlComponentInstance.handleTouchPan(deltaX, isFinal);
    },
    handleMouseMove(normLocalX, normLocalY) {
      if(!this.vueGlEnabled) {
        return;
      }
      this.vueGlComponentInstance.handleMouseMove(normLocalX, normLocalY);
    }
  },
};
</script>
