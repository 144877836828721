import Vue from "vue";
import BeerListVueApp from "./BeerListVueApp.vue";
import createStore from "./store";
export class BeerListApp {
  constructor(el) {
    this.el = el;
    if (!this.el) {
      return;
    }
    this.initVueApp();
  }

  destroy() {
    this.app.$destroy();
  }

  initVueApp() {
    const el = this.el.querySelector(".beer-list-app");

    let initialData = {};
    if (el.dataset.products) {
      initialData = {
        revealDirection: el.dataset.revealDirection || 'right',
        products: JSON.parse(el.dataset.products),
        isBeerLibrary: !!el.dataset.isBeerLibrary
      };
    }

    this.app = new Vue({
      el,
      render: h => h(BeerListVueApp),
      store: createStore(),
      created() {
        this.$store.commit("setInitial", initialData);
      }
    });
  }
}
