import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
    product: null
  },

  mutations: {
    setInitial(state, initial) {
      state.product = initial
    }
  },
});
