import Pristine from 'pristinejs'

class Forms {
  constructor () {
    window.textareaInput = this.handleTextareaInput
    document.addEventListener('submit', e => {
      if (!e.target.classList.contains('wl-form')) return

      this.formEl = e.target
      this.errorBlock = e.target.querySelector('.wl-form__error')
      this.submitBtn = e.target.querySelector('[type=submit]')
      e.preventDefault()

      this.pristine = new Pristine(this.formEl, {
        classTo: 'field',
        errorClass: 'is-invalid',
        successClass: 'is-valid',
        errorTextParent: 'field',
        errorTextTag: 'p',
        errorTextClass: 'help'
      })

      if (!this.pristine.validate()) return

      this.submitBtn.classList.add('is-loading')

      this.handleSubmission().then(() => {
        this.submitBtn.classList.remove('is-loading')
      })
    })
  }

  handleTextareaInput (el) {
    el.style.height = 'auto'
    el.style.height = `${el.scrollHeight}px`
  }

  handleSubmission () {
    const formData = new FormData(this.formEl)
    const gtmTag = this.formEl.querySelector('[value*=gtm_]')

    formData.append('nonce', window.WL.settings.security)

    return fetch(`${window.WL.settings.ajaxUrl}?action=wl_form`, {
      method: 'POST',
      cache: 'no-cache',
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.success) {
          this.errorBlock.innerHTML = response.data.message
          console.error(response.data.error)
        } else {
          gtmTag && dataLayer.push({'event': gtmTag.value.substring(4)});
          this.formEl.innerHTML = response.data.confirmation_message
          this.formEl.classList.add('is-submitted')
          document.body.classList.add(`${this.formEl.id}-is-submitted`)

          if(this.formEl.dataset.openNewTabOnSubmitUrl) {
            window.open(this.formEl.dataset.openNewTabOnSubmitUrl, "_blank");
          }
        }
        this.pristine.destroy()
      })
      .catch((error) => {
        console.error('Error: ', error)
      })
  }
}

export default new Forms()
