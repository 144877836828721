<template>
  <div
    class="featured-beer-list"
    :class="{
      'featured-beer-list--revealed': isRevealed,
    }"
    @click="handleListClick"
  >
    <div
      v-if="vueGlEnabled"
      ref="listModel"
      class="featured-beer-list__model"
    ></div>
    <transition name="fade">
      <div
        v-if="!vueGlEnabled && isRevealed"
        class="featured-beer-list__fallback has-marg-top-6xl has-marg-bottom-5xl"
      >
        <div v-for="product in products" :key="product.id" v-html="product.listingImage" class="featured-beer-list__fallback-figure" />
      </div>
    </transition>
  </div>
</template>

<script>
import { gsap } from "gsap";
import barba from "@barba/core";

export default {
  name: "FeaturedBeerList",
  props: {
    products: {
      type: Array,
      required: true,
    },
    areaLink: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      isRevealed: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.vueGlRegisterComponent({ el: this.$refs.listModel });
    });
  },
  methods: {
    loadAndReveal() {
      if (!this.vueGlEnabled) {
        this.isRevealed = true;
        setTimeout(() => {
          window.WL.app.resetLazyload();
        });
        return Promise.resolve(true);
      }
      return this.vueGlComponentInstance
        .loadAndReveal(this.products)
        .then(() => {
          this.isRevealed = true;
        });
    },
    handleListClick() {
      if (this.areaLink) {
        barba.go(this.areaLink);
      }
    }
  },
};
</script>
