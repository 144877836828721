import Vue from "vue";
import SingleBeerVueApp from "./SingleBeerVueApp.vue";
import store from "./store";

export class SingleBeerApp {
  constructor(el) {
    this.el = el;
    if (!this.el) {
      return;
    }
    this.initVueApp();
  }

  destroy() {
    this.app.$destroy();
  }

  initVueApp() {
    const el = this.el.querySelector(".single-beer-app");

    let initialData = {};
    if (el.dataset.product) {
      initialData = JSON.parse(el.dataset.product);
    }

    this.app = new Vue({
      el,
      render: h => h(SingleBeerVueApp),
      store,
      created() {
        this.$store.commit("setInitial", initialData);
      }
    });
  }
}
