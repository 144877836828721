import Vue from "vue";
import FeaturedBeerVueApp from "./FeaturedBeerVueApp.vue";

export class FeaturedBeerApp {
  constructor(el) {
    this.el = el;
    if (!this.el) {
      return;
    }
    this.initVueApp();
  }

  destroy() {
    this.app.$destroy();
  }

  initVueApp() {
    const el = this.el.querySelector(".featured-beer-app");

    let initialData = {};
    if (el.dataset.products) {
      const products = JSON.parse(el.dataset.products);
      initialData = {
        products,
        areaLink: el.dataset.areaLink
      }
    }

    this.app = new Vue({
      el,
      render: h => h(FeaturedBeerVueApp, {
        props: {
          products: initialData.products,
          areaLink: initialData.areaLink
        }
      }),
    });
  }
}
