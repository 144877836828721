import { AbstractComponent } from '~wl/components/abstract-component';
import { register } from '~wl/components/registration';
import Cookies from 'js-cookie';
import { load } from "~wl/components/registration";
import { scrollLock } from '../utils/scroll';

export class AgeGate extends AbstractComponent {
  init() {
    this.initAgeGate();
    load("marquee", this.el.querySelector('[data-section-component="marquee"]'));
  }

  initAgeGate() {
    // hide if there is a cookie, but always show on checkout page
    if (Cookies.get('age-gate-acceptance') && !window.location.href.includes('checkout')) {
      this.el.remove();
      return;
    }
    scrollLock.disableScroll(this.el, 'age-gate');
    const yesButtonEl = this.el.querySelector('.age-gate__button--yes');
    yesButtonEl.addEventListener('click', () => {
      this.el.classList.add('is-leaving');
      scrollLock.enableScroll('age-gate', true);
      Cookies.set('age-gate-acceptance', 1, { expires: 1 / 24 })
      setTimeout(() => {
        this.el.remove();
      }, 500);
    });
  }
}

register('age-gate', AgeGate);
