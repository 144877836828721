const selectors = {
  class: '.select',
  isActive: 'is-active',
  item: '[data-value]',
}

const activeSelect = {}

const showSelect = () => {
  activeSelect.el.classList.add(selectors.isActive)
}

const hideSelect = () => {
  activeSelect.el.classList.remove(selectors.isActive)
  activeSelect.el = null
}

const initCustomSelect = () => {
  document.addEventListener('click', e => {
    const target = e.target

    if (!target.closest(selectors.class)) {
      if (activeSelect.el) {
        hideSelect()
      }
    } else {
      if (target.matches(selectors.class)) {
        activeSelect.el = target
        activeSelect.select = target.querySelector('select')
        activeSelect.value = target.querySelector('div')
        activeSelect.list = target.querySelector('ul')

        target.classList.contains(selectors.isActive) ? hideSelect() : showSelect()
      }
    }

    if (target.matches(selectors.item) && activeSelect.el) {
      activeSelect.select.value = target.dataset.value
      activeSelect.select.dispatchEvent(new Event('change', {bubbles: true}))
      activeSelect.value.innerHTML = target.innerHTML;

      [...activeSelect.list.children].forEach(el => el.classList.toggle(selectors.isActive, el === target))

      hideSelect()
    }
  })

  // to support when select changed outside or via accessibility
  window.addEventListener('change', e => {
    const target = e.target;
    const el = target.closest(selectors.class)

    if (el) {
      const item = el.querySelector(`[data-value="${target.value}"]`)
      const itemIsActive = item.classList.contains(selectors.isActive)

      if (!itemIsActive) {
        const value = el.querySelector(`div`)
        const list = el.querySelector(`ul`);

        [...list.children].forEach(el => el.classList.toggle(selectors.isActive, el === item))
        value.innerHTML = item.innerHTML
      }
    }
  })

  return false
}

export default initCustomSelect()


