<template>
  <div class="featured-beer-app">
    <template v-if="products">
      <featured-beer-list
        ref="featuredBeerList"
        class="featured-beer-app__list"
        :products="products"
        :areaLink="areaLink"
      />
    </template>
  </div>
</template>

<script>
import FeaturedBeerList from "../../vue-gl/featured-beer-list/FeaturedBeerList.vue";
import { COLOR_OFF_WHITE } from "../../constants/colors";

export default {
  components: { FeaturedBeerList },
  props: ['products', 'areaLink'],
  mounted() {
    this.initVueGlSceneSettings();
    setTimeout(() => {
      this.initTimeline();
    });
  },
  methods: {
    initVueGlSceneSettings() {
      if (this.$vueGl.enabled) {
        this.$vueGl.camera.position.z = window.innerWidth < 768 ? 3.5 : 1.5;
        this.$vueGl.renderer.setClearColor(COLOR_OFF_WHITE, 1);
      }
    },
    initTimeline() {
      const observer = new IntersectionObserver((e) => {
        if (!e[0].isIntersecting) {
          return;
        }
        this.$refs.featuredBeerList.loadAndReveal();
        observer.unobserve(this.$el);
        observer.disconnect();
      });
      observer.observe(this.$el);
    },
  },
  beforeDestroy() {
    if (this.$vueGl.enabled) {
      this.$vueGl.renderer.setClearColor(COLOR_OFF_WHITE, 1);
    }
  },
};
</script>
