<template>
  <div
    class="single-beer-app"
    :style="appStyles"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <single-beer
      ref="singleBeer"
      class="single-beer-app__beer"
      :product="product"
    />
    <a
      v-if="product.nextProductPermalink"
      class="single-beer-app__next-product-link"
      :href="product.nextProductPermalink"
      @click="handleNextProductClick"
    >
      <svg>
        <use xlink:href="#icon-next"></use>
      </svg>
      <span class="single-beer-app__next-prev-label-container">
        <span><span>NEXT</span></span>
        <span><span>BEER</span></span>
      </span>
    </a>
    <a
      v-if="product.prevProductPermalink"
      class="single-beer-app__prev-product-link"
      :href="product.prevProductPermalink"
      @click="handlePrevProductClick"
    >
      <svg>
        <use xlink:href="#icon-prev"></use>
      </svg>
      <span class="single-beer-app__next-prev-label-container">
        <span><span>PREV</span></span>
        <span><span>BEER</span></span>
      </span>
    </a>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Hammer from "hammerjs";
import barba from "@barba/core";
import { gsap } from "gsap";
import SingleBeer from "../../vue-gl/single-beer/SingleBeer.vue";
import { COLOR_OFF_WHITE } from "../../constants/colors";

export default {
  components: { SingleBeer },
  computed: {
    ...mapState(["product"]),
    appStyles() {
      return {
        backgroundColor: this.$vueGl.enabled
          ? "transparent"
          : this.product.themeColor,
      };
    },
  },
  mounted() {
    this.initVueGlSceneSettings();
    this.handleResize();
    setTimeout(() => {
      this.initSingleBeer();
    });
    this.resizeHandler = this.handleResize.bind(this);
    window.addEventListener("resize", this.resizeHandler);

    if (!this.$vueGl.enabled) {
      this.hammertime = new Hammer(this.$el);
      this.hammertime.on('swipe', e => {
        if (e.deltaX > 50) {
          this.handlePrevProductClick();
          barba.go(this.product.prevProductPermalink);
        } else if (e.deltaX < -50) {
          this.handleNextProductClick();
          barba.go(this.product.nextProductPermalink);
        }
      });
    } else {
      this.hammertime = new Hammer(this.$el);
      this.hammertime.on('pan', e => {
        this.$refs.singleBeer.handleTouchPan(e.deltaX, e.isFinal);
      });
    }
  },
  methods: {
    initVueGlSceneSettings() {
      if (this.$vueGl.enabled) {
        this.$vueGl.camera.position.z = 3;
        this.$vueGl.renderer.setClearColor(this.product.themeColor, 1);
      }
    },
    initSingleBeer() {
      this.$refs.singleBeer.loadAndReveal();
    },
    handleMouseEnter() {
      if (!this.$vueGl.enabled || window.isTouchEnabledDevice) {
        return;
      }
      this.$refs.singleBeer.handleMouseEnter();
    },
    handleMouseLeave(e) {
      if (!this.$vueGl.enabled || window.isTouchEnabledDevice) {
        return;
      }
      this.$refs.singleBeer.handleMouseLeave();
    },
    handleResize() {
      this.stageWidth = this.$el.offsetWidth;
      this.stageHeight = this.$el.offsetHeight;
    },
    handleNextProductClick() {
      window.WL.state = window.WL.state || {};
      window.WL.state.productNavigationDirection = "toNext";
    },
    handlePrevProductClick() {
      window.WL.state = window.WL.state || {};
      window.WL.state.productNavigationDirection = "toPrev";
    },
  },
  beforeDestroy() {
    if (this.resizeHandler) {
      window.removeEventListener("resize", this.resizeHandler);
    }
    if (this.$vueGl.enabled) {
      this.$vueGl.renderer.setClearColor(COLOR_OFF_WHITE, 1);
    }
    if (this.hammertime) {
    this.hammertime.destroy();
    }
  },
};
</script>
