import {AbstractComponent} from '~wl/components/abstract-component';
import {register} from '~wl/components/registration';
import {Swiper, Pagination, Lazy, Autoplay} from 'swiper';

Swiper.use([Lazy, Pagination, Autoplay]);

export class Gallery extends AbstractComponent {
  setOptions() {
    this.options = {
      sliders: []
    };
  }

  init() {
    let slides = this.el.querySelectorAll('.swiper-slide')
    this.gallery = this.el.querySelector('.swiper-container')
    if (slides.length > 1 && !document.body.classList.contains('fallback-style')) {
      this.initGallery()
    }
  }

  initGallery() {
    let slider = new Swiper(
      this.gallery,
      {
        speed: 500,
        loop: true,
        preloadImages: false,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true,
        },
        lazy: {
          enabled: true,
          checkInView: true,
          loadOnTransitionStart: true,
          loadPrevNext: true,
        },
        centeredSlides: true,
        watchOverflow: true,
        pagination: {
          el: this.gallery.querySelector('.swiper-pagination'),
          type: 'bullets',
          clickable: true
        },
        on: {
          lazyImageReady: (swiper) => {swiper.update()},
          resize: slider => {
            setTimeout(() => {
              slider.update()
            }, 1550)
          }
        }
      }
    )

    this.options.sliders.push(
      slider
    )
  }

  onUnload() {
    this.options.sliders.forEach(instance => {
      instance.destroy()
    })
  }
}

register('gallery', Gallery);
