import { MathUtils, Mesh, EquirectangularReflectionMapping } from "three";
import { VueGlComponent } from "../../libs/vue-gl/VueGlComponent";

export class CanBottle440ListGl extends VueGlComponent {
  init(
    domElement,
    camera,
    rootScene,
    scrollValue,
    viewportSize,
    componentOptions
  ) {
    super.init(
      domElement,
      camera,
      rootScene,
      scrollValue,
      viewportSize,
      componentOptions
    );

    this.currentXRotationDeg = this.defaultXRotationDeg = 0;
    this.currentYRotationDeg = this.defaultYRotationDeg = 0;
    this.currentZRotationDeg = this.defaultZRotationDeg = 0;

    this.currentX = this.defaultX = 0;
    this.currentY = this.defaultY = -0.43;
    this.currentZ = this.defaultZ = 0;

    this.modelUrl = `${window.WL.settings.assetsUrl}/3d-models/can-bottle-440-separate.glb`;
    this.envMapUrl = `${window.WL.settings.assetsUrl}/3d-models/environment-map-v2.jpg`;

    this.isLoadedAndRevealed = false;

    this.rootScene = rootScene;
    this.rootScene.add(this);
  }

  loadAndReveal(textureUrl) {
    if (this.isLoadedAndRevealed) {
      return Promise.reject('Already loaded and revealed');
    }
    this.isLoadedAndRevealed = true;
    this.textureUrl = textureUrl;
    return Promise.all([
      this.loadModel(this.modelUrl),
      this.loadTexture(this.envMapUrl),
      this.loadTexture(this.textureUrl)
    ]).then(([gltf, envMap, modelMap]) => {
      return this.initModel(gltf, envMap, modelMap);
    });
  }

  initModel(gltf, envMap, modelMap) {
    return new Promise((resolve, reject) => {
      envMap.mapping = EquirectangularReflectionMapping;


      const ratio = this.bounds.width / this.bounds.height;
      this.gltfScene = gltf.scene;

      gltf.scene.children[1].scale.set(0.35, 0.35, 0.35);
      gltf.scene.children[0].scale.set(0.35, 0.35, 0.35);

      const bodyMaterial = gltf.scene.children[0].material;
      bodyMaterial.transparent = false;
      bodyMaterial.color.convertSRGBToLinear();
      bodyMaterial.map = modelMap;

      const topBottomMaterial = bodyMaterial.clone();

      gltf.scene.children[1].material = topBottomMaterial;
      topBottomMaterial.roughness = 0.2;
      topBottomMaterial.metalness = 1;
      topBottomMaterial.envMap = envMap;

      bodyMaterial.roughness = 0.35;
      bodyMaterial.metalness = 0.9;
      bodyMaterial.envMap = envMap;
      // bodyMaterial.refractionRatio = 1;
      bodyMaterial.envMapIntensity = 0.2;

      this.add(this.gltfScene);
      this.rootScene.add(this);

      this.gltfScene.scale.set(1, ratio, 1);

      this.gltfScene.position.set(this.currentX, this.currentY, this.currentZ);
      this.gltfScene.rotation.set(
        MathUtils.degToRad(this.currentXRotationDeg),
        MathUtils.degToRad(this.currentYRotationDeg),
        MathUtils.degToRad(this.currentZRotationDeg)
      );

      resolve(true);
    });
  }

  handleResize(viewportSize) {
    super.handleResize(viewportSize);
    if (!this.gltfScene) {
      return;
    }
    const ratio = this.bounds.width / this.bounds.height;
    this.gltfScene.scale.set(1, ratio, 1);
  }

  handleRaf(delta) {
    super.handleRaf(delta);
    if (!this.gltfScene) {
      return;
    }

    this.gltfScene.position.set(this.currentX, this.currentY, this.currentZ);
    this.gltfScene.rotation.set(
      MathUtils.degToRad(this.currentXRotationDeg),
      MathUtils.degToRad(this.currentYRotationDeg),
      MathUtils.degToRad(this.currentZRotationDeg)
    );
  }
}
