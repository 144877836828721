<template>
  <a
    class="can-bottle-440-list"
    :class="{'is-beer-library': isBeerLibrary}"
    :href="product.permalink"
  >
    <div v-if="vueGlEnabled" ref="canModel" class="can-bottle-440-list__model"></div>
    <transition name="fade">
      <div v-if="!vueGlEnabled && isRevealed" v-html="product.listingImage" class="can-bottle-440-list__fallback-img" />
    </transition>
    <div class="can-bottle-440-list__title-mask">
      <div v-if="isBeerLibrary" class="can-bottle-440-list__release-date" :data-hover="product.title">
        {{ product.release_date }}
      </div>
      <h3 class="can-bottle-440-list__title" :data-hover="product.title">
        {{ product.title }}
      </h3>
    </div>
  </a>
</template>

<script>
import { gsap } from "gsap";

export default {
  name: "CanBottle440List",
  props: {
    product: {
      type: Object,
      required: true,
    },
    isBeerLibrary: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isRevealed: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.vueGlRegisterComponent({ el: this.$refs.canModel });
    })
  },
  methods: {
    loadAndReveal() {
      this.isRevealed = true;
      if (!this.product.textureUrl || !this.vueGlEnabled) {
        return Promise.resolve(true);
      }
      return this.vueGlComponentInstance.loadAndReveal(this.product.textureUrl);
    },
    handleMouseEnter(isActive) {
      if (!this.vueGlEnabled) {
        return;
      }
      gsap.to(this.vueGlComponentInstance, {
        duration: 0.5,
        currentZ: isActive ? 0.2 : 0,
        currentY: isActive ? -0.4 : this.vueGlComponentInstance.defaultY,
        currentXRotationDeg: isActive ? 10 : 0,
      });
    },
    handleMouseLeave() {
      if (!this.vueGlEnabled) {
        return;
      }
      gsap.to(this.vueGlComponentInstance, {
        duration: 0.5,
        currentXRotationDeg: 0,
        currentZ: 0,
        currentY: this.vueGlComponentInstance.defaultY
      });
    },
    updatePosition() {
      if (!this.vueGlComponentInstance) {
        return;
      }
      this.vueGlComponentInstance.updateBounds();
      this.vueGlComponentInstance.updateSize();
      this.vueGlComponentInstance.updatePosition();
    },
  },
};
</script>
