import { AbstractComponent } from "~wl/components/abstract-component";
import { register } from "~wl/components/registration";
import { destroyObservers, initTitleReveal } from "../utils/animations";

export class UserContent extends AbstractComponent {
  init() {
    this.titleRevealObservers = initTitleReveal({ el: this.el, selector: 'h2, h3' });
  }

  onUnload() {
    destroyObservers(this.titleRevealObservers);
  }
}

register("user-content", UserContent);
