import { AbstractComponent } from '~wl/components/abstract-component';
import { register } from '~wl/components/registration';
import { SingleBeerApp } from '../modules/SingleBeer/SingleBeerApp'

export class SingleBeer extends AbstractComponent {
  init() {
    this.singleBeerApp = new SingleBeerApp(this.el);

    if (window.WL.state && window.WL.state.productNavigationDirection) {
      this.el.classList.add(`is-reveal-${window.WL.state.productNavigationDirection === 'toNext' ? 'from-right' : 'from-left'}`);
    }

    setTimeout(() => {
      this.el.classList.add('is-revealed');
    }, 500);
  }

  onUnload() {
    this.singleBeerApp.destroy();
  }
}

register('single-beer', SingleBeer);
