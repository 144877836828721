import Scrollbar from "smooth-scrollbar";
import barba from "@barba/core";

const selectors = {
  el: '[data-minicart]',
  cta: '[data-minicart-cta]',
  counter: '[data-minicart-counter]',
  items: '[data-minicart-items]',
  removeItem: '[data-minicart-remove-item]',
  toggle: '[data-minicart-toggle]',
  cartError: '[data-add-to-cart-error]',
  quantity: '.minicart-quantity',
  isUpdating: 'minicart-is-updating',
  isProcessing: 'minicart-is-processing',
  isShown: 'minicart-is-shown',
  isActive: 'minicart-is-active',
}

let itemsScroll = null
let itemsScrolled = 0

const updateMinicartHtml = (counter, minicart) => {
  if (barba.url.getPath() === '/checkout/') {
    location.reload()
  }

  return new Promise(resolve => {
    document.querySelector(selectors.counter).innerHTML = counter
    document.querySelector(selectors.el).outerHTML = minicart

    if (parseInt(counter) === 0) {
      document.querySelector(selectors.cta).classList.remove('is-hidden')
      document.querySelector(selectors.toggle).classList.add('is-hidden')
    } else {
      document.querySelector(selectors.cta).classList.add('is-hidden')
      document.querySelector(selectors.toggle).classList.remove('is-hidden')
    }

    if (itemsScroll) {
      itemsScrolled = itemsScroll.scrollTop
      initMinicartItemsScroll()
      itemsScroll.scrollTo(0, itemsScrolled)
    }

    setTimeout(() => {
      resolve()
    }, 200)
  })
}

const addItemToCart = data => {
  jQuery.ajax({
    type: 'POST',
    url: woocommerce_params.wc_ajax_url.toString().replace('%%endpoint%%', 'add_to_cart'),
    data: data,
    beforeSend: function () {
      document.body.classList.add(selectors.isProcessing)
    },
    complete: function () {
    },
    success: (response) => {
      console.log('response', response)

      setTimeout(() => {
        document.body.classList.remove(selectors.isProcessing)
      }, 200)

      if (response.error) {
        if (response.error && response.product_url) {
          jQuery.ajax({
            type: 'GET',
            url: response.product_url,
            success: (response) => {
              const doc = new DOMParser().parseFromString(response, 'text/html')
              const error = doc.querySelector('.woocommerce-error').outerHTML

              if (error) {
                document.querySelector(selectors.cartError).innerHTML = error
              }
            }
          })
          return;
        }
        return;
      }

      document.querySelector(selectors.cartError).innerHTML = ''
      jQuery(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash]);
    },
  })
}

const removeItemFromCart = url => {
  jQuery.ajax({
    type: 'GET',
    url: url,
    dataType: 'html',
    beforeSend: function () {
      document.body.classList.add(selectors.isUpdating)
    },
    success: function (response) {
      const doc = new DOMParser().parseFromString(response, 'text/html')
      const minicart = doc.querySelector(selectors.el).outerHTML
      const counter = doc.querySelector(selectors.counter).innerHTML

      updateMinicartHtml(counter, minicart).then(() => {
        document.body.classList.remove(selectors.isUpdating)
      })
    },
  })
}

const updateMinicart = (form) => {
  jQuery.ajax({
    type: 'POST',
    url: form.getAttribute('action'),
    data: new URLSearchParams(new FormData(form)).toString(),
    dataType: 'html',
    beforeSend: function () {
      document.body.classList.add(selectors.isUpdating)
    },
    success: function (response) {
      const doc = new DOMParser().parseFromString(response, 'text/html')
      const minicart = doc.querySelector(selectors.el).outerHTML
      const counter = doc.querySelector(selectors.counter).innerHTML

      updateMinicartHtml(counter, minicart).then(() => {
        document.body.classList.remove(selectors.isUpdating)
      })
    },
  });
}

const onAddToCart = (e, fragments, cart_hash) => {
  updateMinicartHtml(fragments.counter, fragments.minicart).then(() => {
    document.body.classList.add(selectors.isActive)
    setTimeout(() => document.body.classList.add(selectors.isShown), 50)
  })
}

const onQuantityChange = (e) => {
  if (e.target.matches(selectors.quantity)) {
    const maxValue = Number(e.target.max)
    e.target.value = maxValue && e.target.value > maxValue ? maxValue : e.target.value

    updateMinicart(e.target.closest('form'))
  }
}

const onCartSubmit = e => {
  if (e.target.matches('.cart')) {
    e.preventDefault();
    addItemToCart(new URLSearchParams(new FormData(e.target)).toString())
  }

  if (e.target.matches('.minicart')) {
    e.preventDefault();
    e.stopPropagation();
    // updateMinicart(e.target)
  }
}

const initMinicartItemsScroll = () => {
  const items = document.querySelector(selectors.items)
  if (items) {
    itemsScroll = Scrollbar.init(
      document.querySelector(selectors.items), {
        damping: 0.1,
        renderByPixels: true,
        delegateTo: document.querySelector(selectors.el),
        alwaysShowTracks: true,
        continuousScrolling: false
      }
    );
  }
}

const checkForClickActions = e => {
  if (e.target.matches(selectors.toggle)) {
    initMinicartItemsScroll()
    if (document.body.classList.contains(selectors.isActive)) {
      document.body.classList.remove(selectors.isShown)
      setTimeout(() => document.body.classList.remove(selectors.isActive), 300)
    } else {
      document.body.classList.add(selectors.isActive)
      setTimeout(() => document.body.classList.add(selectors.isShown), 50)
    }
  }

  if (e.target.matches(selectors.removeItem)) {
    e.preventDefault();
    removeItemFromCart(e.target.dataset.url)
  }
}

document.addEventListener('click', checkForClickActions)
document.addEventListener('submit', onCartSubmit)
document.addEventListener('change', onQuantityChange)
jQuery(document.body).on('added_to_cart removed_from_cart', onAddToCart)
