import {
  WebGLRenderer,
  PerspectiveCamera,
  Scene,
  sRGBEncoding,
  CineonToneMapping,
  DirectionalLight
} from "three";
import { AbstractComponent } from "~wl/components/abstract-component";
import { register } from "~wl/components/registration";
import { COLOR_OFF_WHITE } from "../constants/colors";

export class GlCanvas extends AbstractComponent {
  init() {
    if (window.WL.vueGl.core.enabled) {
      this.initCanvas();
    } else {
      this.el.remove();
    }
  }

  initCanvas() {
    const canvasEl = this.el;
    const renderer = new WebGLRenderer({
      powerPreference: "high-performance",
      canvas: canvasEl,
      antialias: false,
      stencil: false,
      alpha: false
      // depth: false,
    });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(2);
    renderer.setClearColor(COLOR_OFF_WHITE, 1);
    renderer.setScissorTest(true);
    renderer.toneMapping = CineonToneMapping;
    renderer.toneMappingExposure = 5.5;
    // renderer.physicallyCorrectLights = true
    renderer.outputEncoding = sRGBEncoding;

    // Camera config
    const fov = 45;
    const aspect = window.innerWidth / window.innerHeight;
    const near = 0.1;
    const far = 20;
    const camera = new PerspectiveCamera(fov, aspect, near, far);
    camera.position.z = 7;

    const rootScene = new Scene();

    // this.pl = new PointLight('#065e00', 3);
    // this.pl.position.set(0, 20, 30);
    // rootScene.add(this.pl);

    this.light = new DirectionalLight('#ffffff', 2);
    this.light.position.set(-800, 800, 800);

    this.light2 = new DirectionalLight('#ffffff', 2);
    this.light2.position.set(800, 800, 800);

    // this.light.decay = 2;
    rootScene.add(this.light);
    rootScene.add(this.light2);

    window.WL.vueGl.core.registerCanvas(
      this,
      this.el,
      camera,
      renderer,
      rootScene,
      delta => {
        renderer.render(rootScene, camera);
      }
    );

    window.WL.vueGl.core.registerScroll();
  }

  onUnload() {
    window.WL.vueGl.core.deregisterCanvas();
  }
}

register("gl-canvas", GlCanvas);
