import Hammer from "hammerjs";
import barba from "@barba/core";
import { AbstractComponent } from '~wl/components/abstract-component';
import { register } from '~wl/components/registration';

export class SingleMerchandise extends AbstractComponent {
  init() {
    if (window.WL.state && window.WL.state.productNavigationDirection) {
      this.el.classList.add(`is-reveal-${window.WL.state.productNavigationDirection === 'toNext' ? 'from-right' : 'from-left'}`);
    }

    this.nextProductPermalink = this.el.dataset.nextProductPermalink;
    this.prevProductPermalink = this.el.dataset.prevProductPermalink;

    this.boundNextLinkClickHandler = this.handleNextProductClick.bind(this);
    this.boundPrevLinkClickHandler = this.handlePrevProductClick.bind(this);

    this.nextLink = this.el.querySelector('.single-merchandise-page__content-image-next-product-link');
    this.nextLink.addEventListener('click', this.boundNextLinkClickHandler);

    this.prevLink = this.el.querySelector('.single-merchandise-page__content-image-prev-product-link');
    this.prevLink.addEventListener('click', this.boundPrevLinkClickHandler);

    if (!window.WL.vueGl.core.enabled) {
      this.hammertime = new Hammer(this.el.querySelector('.single-merchandise-page__content-image'));
      this.hammertime.on('swipe', e => {
        if (e.deltaX > 50) {
          this.handlePrevProductClick();
          barba.go(this.prevProductPermalink);
        } else if (e.deltaX < -50) {
          this.handleNextProductClick();
          barba.go(this.nextProductPermalink);
        }
      });
    }

    setTimeout(() => {
      this.el.classList.add('is-revealed');
    }, 500);
  }

  handleNextProductClick() {
    window.WL.state = window.WL.state || {};
    window.WL.state.productNavigationDirection = 'toNext';
  }

  handlePrevProductClick() {
    window.WL.state = window.WL.state || {};
    window.WL.state.productNavigationDirection = 'toPrev';
  }

  onUnload() {
    this.nextLink.removeEventListener('click', this.boundNextLinkClickHandler);
    this.prevLink.removeEventListener('click', this.boundPrevLinkClickHandler);
  }
}

register('single-merchandise', SingleMerchandise);
