(function () {
  document.addEventListener('click', e => {
    const link = e.target.getAttribute("href");
    if (!link || !link.startsWith('#')) return;
    e.preventDefault()

    const target = document.querySelector(link)
    target && window.scrollTo({
      top: window.pageYOffset + target.getBoundingClientRect().top,
      behavior: 'smooth'
    })
  })
})()
