export function calculateCameraUnitSize(
  distance = this.camera.position.z,
  camera
) {
  const vFov = (camera.fov * Math.PI) / 180;
  const height = 2 * Math.tan(vFov / 2) * distance;
  const width = height * camera.aspect;
  return {
    width,
    height
  };
}

// Emulates css background-size: cover for textures
export function calculateCoverScale(aspectOfPlane, aspectOfImage) {
  let yScale = 1;
  let xScale = aspectOfPlane / aspectOfImage;
  if (xScale > 1) {
    xScale = 1;
    yScale = aspectOfImage / aspectOfPlane;
  }
  return {
    x: xScale,
    y: yScale
  };
}

export function isWebGLAvailable() {
  try {
    const canvas = document.createElement("canvas");
    return !!(
      !(
        document.body.classList.contains('page-checkout') ||
        document.body.classList.contains('page-my-account')
      ) &&
      window.WebGLRenderingContext &&
      (canvas.getContext("webgl") || canvas.getContext("experimental-webgl"))
    );
  } catch (e) {
    return false;
  }
}
