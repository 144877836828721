import { scrollLock } from "../utils/scroll";

class Header {
  constructor() {
    this.navToggle = document.querySelector(".nav-toggle");
    this.navMenu = document.querySelector(".nav-menu");
    this.trigger = document.querySelector("[data-header-trigger]");
    this.isBusy = false;
    this.navToggle.addEventListener("click", () => this.handleNavToggleClick());
    this.pageYOffset = window.scrollYl;
    this.stageWidth = window.innerWidth;
    this.lastScrollY = window.pageYOffset;
    window.addEventListener('resize', () => { this.handleResize() })
    this.initFooterIntersection();
    this.initHeaderTrigger();
  }

  initFooterIntersection() {
    this.footerIntersectionObserver = new IntersectionObserver((e) => this.handleFooterIntersection(e), {
      rootMargin: "0px 0px -95% 0px"
    });
  }

  initHeaderTrigger() {
    new IntersectionObserver(entries => {
      document.body.classList.toggle('has-passed-header-trigger', !entries[0].isIntersecting)
    }).observe(this.trigger);
  }

  updateFooterIntersection(footerEl) {
    if (this.footerEl) {
      this.footerIntersectionObserver.unobserve(this.footerEl);
    }
    this.footerEl = footerEl;
    this.footerIntersectionObserver.observe(this.footerEl);
  }

  handleResize() {
    this.stageWidth = window.innerWidth;
    if (this.stageWidth < 769 && this.isAtBottom) {
      this.navToggle.classList.remove('is-at-bottom');
      this.isAtBottom = false;
    }
  }

  handleFooterIntersection(e) {
    if (this.stageWidth < 769) {
      return;
    }
    const newIsAtBottom = e[0].isIntersecting;
    if(this.isAtBottom !== newIsAtBottom) {
      this.isAtBottom = newIsAtBottom;

      if (newIsAtBottom) {
        document.body.classList.add('is-at-bottom');
      } else {
        document.body.classList.remove('is-at-bottom');
      }
    }
  }

  handleNavToggleClick() {
    if (this.isBusy) {
      return;
    }
    this.isBusy = true;
    const bodyClass = document.body.classList;
    bodyClass.contains("is-menu-open")
      ? this.closeMenu()
      : this.openMenu();
    bodyClass.toggle("is-menu-open");
  }

  closeMenu() {
    this.navMenu.classList.add("is-left");
    setTimeout(() => {
      scrollLock.enableScroll('menu');
      this.navMenu.classList.remove("is-visible", "is-left", "is-revealing", "is-revealed");
      this.isBusy = false;
    }, 500);
  }

  openMenu() {
    scrollLock.disableScroll(this.navToggle, 'menu');
    this.navMenu.classList.add("is-visible");
    setTimeout(() => {
      this.navMenu.classList.add("is-revealing");
      setTimeout(() => {
        this.isBusy = false;
        this.navMenu.classList.remove("is-revealing");
        this.navMenu.classList.add("is-revealed");
      }, 500);
    }, 50);
  }
}

const header = new Header();
window.WL.header = header;

export default header;


