<template>
  <div
    class="beer-list-app"
    :class="{
      'beer-list-app--revealed-ready': isRevealedReady,
    }"
  >
    <div class="beer-list-app__products-viewport">
      <div
        ref="scroller"
        class="beer-list-app__products-scroller columns is-multiline is-mobile is-gapless"
      >
        <template v-if="products">
          <div
            v-for="(product, index) in products"
            :key="product.id"
            class="featured-beer-app__products-item column is-6-mobile is-6-tablet is-4-desktop is-3-widescreen"
          >
            <can-bottle-440-list
              :is-beer-library="isBeerLibrary"
              class="featured-beer-app__products-item-can"
              ref="canBottle"
              :product="product"
              @mouseenter.native="handleCanMouseEnter(product, index)"
              @mouseleave.native="handleCanMouseLeave(product)"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { gsap } from "gsap";
import CanBottle440List from "../../vue-gl/can-bottle-440-list/CanBottle440List.vue";
import { COLOR_OFF_WHITE } from "../../constants/colors";

export default {
  components: { CanBottle440List },
  data() {
    return {
      hoveredIndex: null,
      isRevealedReady: false,
    };
  },
  computed: {
    ...mapState(["products", "revealDirection", "isBeerLibrary"]),
  },
  mounted() {
    this.initVueGlSceneSettings();
    setTimeout(() => {
      this.initTimeline();
    });
  },
  methods: {
    initVueGlSceneSettings() {
      if (this.$vueGl.enabled) {
        this.$vueGl.camera.position.z = 8;
        this.$vueGl.renderer.setClearColor(COLOR_OFF_WHITE, 1);
      }
    },
    initTimeline() {
      this.tl = gsap
        .timeline({
          paused: true,
          onComplete: () => {
            this.isRevealedReady = true;
            this.$refs.canBottle.forEach((c, i) => {
              c.updatePosition();
            });
          },
        })
        .fromTo(
          this.$refs.scroller,
          { xPercent: this.revealDirection === "right" ? 100 : -100 },
          { xPercent: 0, duration: this.$vueGl.enabled ? 1.5 : 0.01,
            onUpdate: () => {
              this.$refs.canBottle.forEach((c, i) => {
                c.updatePosition();
              });
            }
          }
        );

      if (this.$vueGl.enabled) {
        this.$refs.canBottle.forEach((c, i) => {
          const rotationYFromDeg =
            this.revealDirection === "right" ? 1000 + 200 * i : -1000 - 200 * i;
          const xFrom =
            this.revealDirection === "right"
              ? 2.5 * i
              : -(this.$refs.canBottle.length - i) * 2.5;
          this.tl.fromTo(
            c.vueGlComponentInstance,
            { currentYRotationDeg: rotationYFromDeg, currentX: xFrom },
            {
              currentYRotationDeg: c.vueGlComponentInstance.defaultYRotationDeg,
              currentX: c.vueGlComponentInstance.defaultX,
              duration: 1.5
            },
            0
          );
        });
      }

      const observer = new IntersectionObserver((e) => {
        if (!e[0].isIntersecting) {
          return;
        }
        const promises = this.$refs.canBottle.map((c, i) =>
          c.loadAndReveal()
        );
        Promise.all(promises).then(() => {
          this.tl.play();
        });
        observer.unobserve(this.$el);
        observer.disconnect();
      });
      observer.observe(this.$el);
    },
    handleCanMouseEnter(can, index) {
      if (!this.isRevealedReady) {
        return;
      }
      this.$refs.canBottle.forEach((c, i) => {
        c.handleMouseEnter(i === index);
      });
    },
    handleCanMouseLeave() {
      if (!this.isRevealedReady) {
        return;
      }
      this.$refs.canBottle.forEach((c) => {
        c.handleMouseLeave();
      });
    },
  },
  beforeDestroy() {
    this.tl.kill();
    if (this.$vueGl.enabled) {
      this.$vueGl.renderer.setClearColor(COLOR_OFF_WHITE, 1);
    }
  },
};
</script>
