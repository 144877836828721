import { Object3D, TextureLoader, sRGBEncoding } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { calculateCameraUnitSize } from "./utils";
export class VueGlComponent extends Object3D {
  constructor() {
    super();

    this.domElement = null;
    this.camera = null;
    this.viewportSize = null;

    this.scrollValue = { x: 0, y: 0 };
    this.normalisedScrollValue = { x: 0, y: 0 };
    this.normalisedViewportMouseCoordinates = { x: 0, y: 0 };
    this.normalisedLocalMouseCoordinates = { x: 0, y: 0 };
    this.normalisedLocalMouseEnterCoordinates = { x: 0, y: 0 };
    this.normalisedLocalMouseLeaveCoordinates = { x: 0, y: 0 };
    // scroll in viewport 0 - 1
    this.normalisedScrollViewportProgress = { x: 0, y: 0 };
    this.bounds = null;
    this.normalisedBounds = null;
    this.rootScene = null;
    // TODO ignores scrolling if true, might need a better solution
    this.isFixed = false;

    this.isIntersecting = false;
  }

  init(
    domElement,
    camera,
    rootScene,
    scrollValue,
    viewportSize,
    componentOptions
  ) {
    this.domElement = domElement;
    this.camera = camera;
    this.rootScene = rootScene;
    this.scrollValue = scrollValue;
    this.viewportSize = viewportSize;
    this.isFixed = componentOptions && componentOptions.isFixed;
    this.handleResize(this.viewportSize);

    this.intersectionObserverHandler = this.handleIntersectionObserver.bind(
      this
    );
    this.intersectionObserver = new IntersectionObserver(
      this.intersectionObserverHandler
    );
    this.intersectionObserver.observe(this.domElement);
  }

  loadModel(url) {
    return new Promise((resolve, reject) => {
      if (!window.WL.vueGl.modelLoader) {
        window.WL.vueGl.modelLoader = new GLTFLoader();
      }
      // window.WL.vueGl.modelsCache = window.WL.vueGl.modelsCache || {};
      // if (window.WL.vueGl.modelsCache[url]) {
      //   resolve(window.WL.vueGl.modelsCache[url]);
      // }

      window.WL.vueGl.modelLoader.load(
        url,
        gltf => {
          // window.WL.vueGl.modelsCache[url] = gltf;
          resolve(gltf);
        },
        null,
        e => {
          reject(e);
        }
      );
    });
  }

  loadTexture(url) {
    return new Promise((resolve, reject) => {
      if (!window.WL.vueGl.textureLoader) {
        window.WL.vueGl.textureLoader = new TextureLoader();
      }
      window.WL.vueGl.texturesCache = window.WL.vueGl.texturesCache || {};
      if (window.WL.vueGl.texturesCache[url]) {
        resolve(window.WL.vueGl.texturesCache[url]);
      }

      window.WL.vueGl.textureLoader.load(
        url,
        texture => {
          texture.encoding = sRGBEncoding;
          texture.flipY = false;
          window.WL.vueGl.texturesCache[url] = texture;
          resolve(texture);
        },
        null,
        e => {
          reject(e);
        }
      );
    });
  }

  updateViewport(viewportSize) {
    this.viewportSize = viewportSize;
  }

  handleScroll(scrollValue) {
    this.scrollValue = scrollValue;
  }

  handleResize(viewportSize, scrollValue) {
    this.updateViewport(viewportSize);

    if (scrollValue) {
      this.scrollValue = scrollValue;
    }

    this.updateBounds();
    this.updateSize();
    this.updatePosition();
  }

  handleIntersectionObserver(e) {
    this.isIntersecting = e[0].isIntersecting;
  }

  handleMouseEnter(mX, mY) {
    this.localMouseEnterCoordinates = { x: mX, y: mY };
  }

  handleMouseLeave(mX, mY) {
    this.localMouseLeaveCoordinates = { x: mX, y: mY };
  }

  updateMouseCoordinates(normalisedViewportMouseCoordinates) {
    this.normalisedViewportMouseCoordinates = normalisedViewportMouseCoordinates;

    // this.normalisedLocalMouseCoordinates = {
    //   x:
    //     (normalisedViewportMouseCoordinates.x - this.normalisedBounds.left) /
    //     this.normalisedBounds.width,
    //   y:
    //     (normalisedViewportMouseCoordinates.y -
    //       this.normalisedBounds.top +
    //       this.scrollValue.y / this.viewportSize.height) /
    //     this.normalisedBounds.height
    // };
  }

  updateNormalisedBounds() {
    this.normalisedBounds = {
      width: this.bounds.width / this.viewportSize.width,
      height: this.bounds.height / this.viewportSize.height,
      left: this.bounds.left / this.viewportSize.width,
      top: this.bounds.top / this.viewportSize.height
    };
  }

  updateBounds(bounds = null) {
    if (bounds) {
      this.bounds = bounds;
      this.updateNormalisedBounds();
      return;
    }
    if (this.domElement) {
      const rect = this.domElement.getBoundingClientRect();
      this.bounds = {
        left: rect.left + this.scrollValue.x,
        top: rect.top + this.scrollValue.y,
        width: rect.width,
        height: rect.height
      };
      this.updateNormalisedBounds();
    }
  }

  updateSize() {
    if (!this.bounds) {
      return;
    }

    this.camUnit = calculateCameraUnitSize(
      this.camera.position.z - this.position.z,
      this.camera
    );

    if (!this.normalisedBounds.width || !this.normalisedBounds.height) {
      return;
    }

    this.scale.x = this.camUnit.width * this.normalisedBounds.width;
    this.scale.y = this.camUnit.height * this.normalisedBounds.height;
  }

  updatePosition() {
    if (!this.camUnit || !this.scale || !this.isIntersecting) {
      this.visible = false;
      return;
    }
    this.visible = true;
    const y = this.isFixed ? 0 : this.scrollValue.y;

    // Set origin to top left
    this.position.x = -this.camUnit.width / 2 + this.scale.x / 2;
    this.position.y = this.camUnit.height / 2 - this.scale.y / 2;

    // Set position
    this.position.x +=
      (this.bounds.left / this.viewportSize.width) * this.camUnit.width;
    this.position.y -=
      ((this.bounds.top - y) / this.viewportSize.height) * this.camUnit.height;

    const distance = y + this.viewportSize.height - this.bounds.top;
    const percentage = Math.round(
      distance / ((this.viewportSize.height + this.bounds.height) / 100)
    );
    this.normalisedScrollViewportProgress.y =
      Math.min(100, Math.max(0, percentage)) / 100;
  }

  handleRaf() {
    this.updatePosition();
  }

  destroy() {
    this.rootScene && this.rootScene.remove(this);
    // TODO make sure it's destroyed
    this.intersectionObserver.unobserve(this.domElement);
    this.intersectionObserver.disconnect();
    this.intersectionObserver = null;
  }
}
