import { AbstractComponent } from '~wl/components/abstract-component';
import { register } from '~wl/components/registration';
import Cookies from 'js-cookie';

export class CookiesPopup extends AbstractComponent {
  init() {
    if (Cookies.get('cookies-acceptance')) {
      this.el.remove();
      return;
    }
    const yesButtonEl = this.el.querySelector('.cookies-popup__button--ok');
    yesButtonEl.addEventListener('click', () => {
      this.el.classList.add('is-leaving');
      Cookies.set('cookies-acceptance', 1, { expires: 7 })
      setTimeout(() => {
        this.el.remove();
      }, 500);
    });
  }
}

register('cookies-popup', CookiesPopup);
