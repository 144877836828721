import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

class ScrollLock {
  lockLabel = null;

  disableScroll(el, lockLabel) {
    if (this.lockLabel) {
      return;
    }
    this.lockLabel = lockLabel;
    disableBodyScroll(el, { reserveScrollBarGap: true });
    document.body.classList.add("no-scroll");
    if (window.smoothScrollInstace) {
      window.smoothScrollInstace.updatePluginOptions("modal", { open: true });
    }
  }

  enableScroll(lockLabel, resetScroll = false) {
    if (lockLabel !== this.lockLabel) {
      return;
    }
    this.lockLabel = null;
    document.body.classList.remove("no-scroll");
    clearAllBodyScrollLocks();
    if (window.smoothScrollInstace) {
      window.smoothScrollInstace.updatePluginOptions("modal", { open: false });
    }

    if (resetScroll) {
      if (window.smoothScrollInstace) {
        window.smoothScrollInstace.scrollTo(0, 0, 0);
      } else {
        window.scrollTo(0, 0);
      }
    }
  }
}

export const scrollLock = new ScrollLock();
